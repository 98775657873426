import React, { useState } from 'react'
import styled from 'styled-components'
import { useToggle } from 'hooks/use-toggle'
import H2L from 'components/typography/h2'
import { Helmet } from 'react-helmet'
import SimilarProducts from 'components/similar-products'
import { ManitouBrand, ManitouOrganization } from 'data/schema-data'
import Layout from 'components/layout'
import Content from 'components/content'
import { graphql } from 'gatsby'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import TopFold from 'components/products/top-fold'
import QuoteForm from 'components/forms/quote'
import Modal from 'components/modal/modal'
import { getSrc } from 'gatsby-plugin-image'

const ManitouProductTemplate = props => {
  const {
    data: { sanityManitouEquipment },
  } = props

  const [{ breadcrumbs, images, meta, productSchema, similarProducts }] = useState(() => {
    const breadcrumbs = [
      {
        name: 'Home',
        schemaName: 'Hutson Inc',
        link: '/',
      },
      {
        name: 'Manitou',
        link: '/manitou/',
      },
      {
        name: sanityManitouEquipment.category.title,
        link: `/manitou/${sanityManitouEquipment.category.handle}/`,
      },
      {
        name: sanityManitouEquipment.subcategory.title,
        link: `/manitou/${sanityManitouEquipment.category.handle}/${sanityManitouEquipment.subcategory.handle}/`,
      },
      {
        name: sanityManitouEquipment.series.title,
        link: `/manitou/${sanityManitouEquipment.category.handle}/${sanityManitouEquipment.subcategory.handle}/${sanityManitouEquipment.series.handle}/`,
      },
      {
        name: sanityManitouEquipment.title,
        link: sanityManitouEquipment.slug,
      },
    ]

    const similarProducts = Array.isArray(sanityManitouEquipment.similarEquipment)
      ? sanityManitouEquipment.similarEquipment.slice(0, 3).map(obj => {
          return {
            id: obj._id,
            image: getSrc(obj.firstImage.asset.gatsbyImageData),
            imageAlt: obj.title,
            link: obj.slug,
            title: obj.title,
          }
        })
      : []

    const meta = {
      title: `Manitou ${sanityManitouEquipment.title} | Hutson Inc`,
      description: `Learn more about the Manitou ${sanityManitouEquipment.title} at Hutson.`,
      pageUrl: `https://www.hutsoninc.com${sanityManitouEquipment.slug}`,
      images: [],
    }

    const images = sanityManitouEquipment.images.map((image, index) => {
      const original = getSrc(image.asset.original)
      const thumbnail = getSrc(image.asset.thumbnail)

      meta.images.push(original)

      return {
        original,
        thumbnail,
        originalAlt: sanityManitouEquipment.title,
        thumbnailAlt: `Photo ${index + 1} of ${sanityManitouEquipment.images.length}`,
      }
    })

    const productSchema = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      'description': meta.description,
      'name': sanityManitouEquipment.title,
      'url': meta.pageUrl,
      'image': meta.images[0],
      'sku': sanityManitouEquipment.sku,
      'category': `${sanityManitouEquipment.category.title}/${sanityManitouEquipment.subcategory.title}`,
      'brand': ManitouBrand,
      'manufacturer': ManitouOrganization,
    }

    return {
      breadcrumbs,
      images,
      meta,
      productSchema: JSON.stringify(productSchema),
      similarProducts,
    }
  })

  const [open, toggleModal] = useToggle(false)
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta property='og:title' content={meta.title} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:url' content={meta.pageUrl} />
        {meta.images.map((img, key) => (
          <meta property='og:image' content={img} key={key} />
        ))}
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
        <script type='application/ld+json'>{productSchema}</script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Content>
        <TopFold
          title={`Manitou ${sanityManitouEquipment.title}`}
          description={sanityManitouEquipment.description}
          images={images}
          toggleModal={toggleModal}
          breadcrumbs={breadcrumbs}
        />

        {Array.isArray(sanityManitouEquipment.specifications) &&
        sanityManitouEquipment.specifications.length > 0 ? (
          <Section>
            <SpecsTable>
              <thead>
                <tr>
                  <td colSpan='2'>Manitou {sanityManitouEquipment.title} Specifications</td>
                </tr>
              </thead>
              <tbody>
                {sanityManitouEquipment.specifications.map(spec => (
                  <tr key={spec.title}>
                    <td>{spec.title}</td>
                    <td>{spec.description}</td>
                  </tr>
                ))}
              </tbody>
            </SpecsTable>
          </Section>
        ) : null}

        {Array.isArray(similarProducts) && similarProducts.length > 0 ? (
          <Section>
            <H2L>Similar Products</H2L>
            <SimilarProducts similarProducts={similarProducts} />
          </Section>
        ) : null}
      </Content>

      <Modal toggle={toggleModal} isOpen={open}>
        <QuoteForm
          toggleModal={toggleModal}
          productName={sanityManitouEquipment.title}
          productPrice={0}
          productType='manitou'
          pageURL={meta.pageUrl}
          equipmentBrand='Manitou'
          equipmentCondition='new'
        />
      </Modal>
    </Layout>
  )
}

const Section = styled.div`
  margin-top: 40px;

  @media (min-width: 800px) {
    margin-top: 80px;
  }
`

const SpecsTable = styled.table`
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  thead {
    color: #fff;
    background-color: #000;
    font-size: 1.35em;
    font-weight: bold;
    margin: 0;
    text-transform: none;
    vertical-align: middle;

    td {
      padding: 15px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #d7d7d7;
      display: block;
      padding: 15px;

      :nth-child(even) {
        background-color: #f7f7f7;
      }
    }

    td {
      padding: 0;
      display: block;

      :first-child {
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }

  @media (min-width: 800px) {
    thead {
      display: table-header-group;
    }

    tbody {
      tr {
        display: table-row;
        padding: auto;
      }

      td {
        display: table-cell;
        margin: auto;
        padding: 15px;

        :first-child {
          border-right: 1px solid #d7d7d7;
          width: 200px;
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query manitouProductTemplate($_id: String!) {
    sanityManitouEquipment(_id: { eq: $_id }) {
      category {
        handle
        title
      }
      images {
        asset {
          original: gatsbyImageData(width: 680)
          thumbnail: gatsbyImageData(layout: FIXED, width: 92)
        }
      }
      series {
        handle
        title
      }
      similarEquipment {
        _id
        firstImage {
          asset {
            gatsbyImageData(width: 640)
          }
        }
        slug
        title
      }
      sku
      slug
      specifications {
        description
        title
      }
      subcategory {
        handle
        title
      }
      title
    }
  }
`

export default ManitouProductTemplate
